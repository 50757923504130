<template>
<div>
  <div v-if="showLoading" class="text-center" style="padding: 24px">
    <CSpinner/>
  </div>

  <CRow>
    <CCol>
      <ul>
        <li>
          <div style="font-size:small; margin-bottom:10px">Perhatian: Jurnal Pemuridan semua kelompok hanya untuk Tim Pembina, demi keperluan doa, pelayanan untuk kebutuhan khusus, dan juga perencanaan program pemuridan.</div>

          <CButton
            color="success"
            variant="outline"
            square
            size="sm"
            @click="onClickDownloadJournal()">
            Download Journal Pemuridan semua kelompok
          </CButton>
        </li>
        <li>
          <div style="font-size:small; margin-bottom:10px">Data user berisi data pribadi seperti nomor HP dan email. Mohon hanya digunakan untuk keperluan administrasi Sion Academy</div>

          <CButton
            color="success"
            variant="outline"
            square
            size="sm"
            @click="onClickDownloadUser()">
            Download data semua user
          </CButton>
        </li>
      </ul>
    </CCol>
  </CRow>

</div>
</template>

<script>
  import api from "../../api/api"

  export default {
    data () {
      return {
        showLoading: false,
        items: [],
        user: '',
        members: []
      }
    },

    async created () {
      var idToken = localStorage.getItem('idToken')

      if(idToken===null || idToken===undefined || idToken==='undefined')
        this.$router.replace('/login', () => {})
      else{
        this.user = JSON.parse(localStorage.getItem('user'))
        this.listJournalPa ()
      }
    },

    methods: {
      async listJournalPa () {
        this.items = []
        this.showLoading = true
        let apiRes = await api.listJournalPA(this.user.id, "ALL")
        this.showLoading = false

        if (apiRes.result === 'OK'){
          this.items = apiRes.items
          this.listMember()
        }
        else {
          console.log("listJournalPa ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },

      async listMember () {
        this.members = []
        this.showLoading = true
        let apiRes = await api.listMember()
        this.showLoading = false

        if (apiRes.result === 'OK')
          this.members = apiRes.members
        else {
          console.log("listMember ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },

      onClickDownloadJournal(){
        var csvFileData = []
        for(var i=0; i<this.items.length; i++){
          var item = []
          item.push(this.items[i].meetingDateFormatted)
          item.push(this.items[i].mentorName)
          item.push(this.items[i].mentorCity)
          item.push(this.items[i].moduleName)
          item.push(this.items[i].moduleNo + "-" + this.items[i].noSesi)

          var attendees = ""
          for(var k=0; k<this.items[i].attendees.length; k++){
            attendees += this.items[i].attendees[k].name
            if(k<this.items[i].attendees.length-1) attendees += ", "
          }
          item.push(attendees)
          var note = this.items[i].note.replace(/[\n\r]/g,' ')
          item.push(note)

          csvFileData.push(item)
        }

        var n = 0;
        var csv = 'Tanggal\tPembimbing\tKota\tModul\tSesi\tPeserta\tCatatan\n'    
        csvFileData.forEach(function(row) {
                csv += row.join('\t')
                csv += "\n"
        })

        var hiddenElement = document.createElement('a')
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
        hiddenElement.target = '_blank'
        hiddenElement.download = 'Jurnal-Pemuridan-Semua-Kelompok-' + this.user.name + '.csv'
        hiddenElement.click()
      },

      onClickDownloadUser(){
        var csvFileData = []
        for(var i=0; i<this.members.length; i++){
          var item = []
          item.push(this.members[i].name)
          item.push(this.members[i].access)
          item.push(this.members[i].city)
          item.push(this.members[i].email)
          item.push(this.members[i].mobile)
          item.push(this.members[i].mentor)
          item.push(this.members[i].campus)
          item.push(this.members[i].yearIn)
          item.push(this.members[i].major)

          csvFileData.push(item)
        }
        var csv = 'Name\tAkses\tKota\tEmail\tHP\tMentor\tKampus\tAngkatan\tJurusan\n'
    
        csvFileData.forEach(function(row) {
                csv += row.join('\t')
                csv += "\n"
        })

        console.log("CSV: ", csv);

        var hiddenElement = document.createElement('a')
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
        hiddenElement.target = '_blank'
        hiddenElement.download = 'DaftarAnggotaSion-' + this.user.name + '.csv'
        hiddenElement.click()
      },
    },

  }
</script>
